import React, { useContext } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import * as formConfirmationStyles from "./formConfirmation.module.scss"

const GeorgiaBoot4hCauseConfirmation = () => {
  const pageTitle = "Form Confirmation"

  return (
    <Layout>
      <div className={formConfirmationStyles.heroWrapper}>
        <div
          className={formConfirmationStyles.heroImageWrapper}
          id="inner-header-1-hero"
        >
          <StaticImage
            src="../assets/images/global/page-partials/inner-header-1/factory-TB.png"
            className={formConfirmationStyles.heroImageMB}
            alt=""
          />
          <StaticImage
            src="../assets/images/global/page-partials/inner-header-1/factory-TB.png"
            className={formConfirmationStyles.heroImageTB}
            alt=""
          />
          <StaticImage
            src="../assets/images/global/page-partials/inner-header-1/factory-DT.png"
            className={formConfirmationStyles.heroImageDT}
            alt=""
          />
        </div>
      </div>
      <div
        className={`inner-y-padding inner-container ${formConfirmationStyles.contentWrapper}`}
      >
        <h1>Thank You!</h1>
        <p>
          Thank you for submitting the form. The Georgia Boot Marketing Manager
          will follow up with more program details.
        </p>
      </div>
    </Layout>
  )
}

export default GeorgiaBoot4hCauseConfirmation
